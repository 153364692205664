.login {
  min-height: 100vh;
  
  background-image: url('../../assets/images/login-background.png');
  background-position: center;
  background-size: cover;
  background-position-y: 0px;
  background-repeat: no-repeat;
  box-shadow: inset 0 0 0 1000px rgba(0,0,0,.5);

  .btn-blue {
    margin-top: 20px;
    width: 100%;
    max-width: 100%;
    padding: 10px;
    border-radius: 3px;
  }

  .login-text {
    margin: 10px 0;
    max-width: 442px;
    font-size: 40px;
    color: #cccccc;
    font-size: 37px;
  }

  .read-more,
  .platform-text {
    font-size: 16px;
    font-weight: 100;
    color: $white;
  }

  .login-title {
    margin-bottom: 0;

    font-size: 23px;
    font-weight: 800;
    color: #393E46;
    text-align: center;
  }

  .login-description {
    margin-top: 3px;
    
    font-size: 16px;
    color: #393E46;
    text-align: center;
  }

  .login-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 100%;
    min-height: 100vh;

    .brand-wrapper {
      align-self: center;

      color: $white;
    }
  
    .form-wrapper,
    .bg-wrapper {
      height: 100%;
      min-height: 100vh;
    }
  
    .form-wrapper {
      display: flex;
      justify-content: center;
      flex-direction: column;
      // align-items: center;
      // flex: 0 0 40%;
      width: 50%;
      max-width: 430px;
      height: 100%;
      // min-height: 528px;
      min-height: auto;

      .form-content {
        // padding: 50px;
        // padding: 72px 50px 0;
        padding: 54px 41px 0;
        // width: 100%;
        // max-width: 350px;
        width: 100%;
        
        background: $white;
        border-radius: 5px;
      }
  
      .form {
        display: flex;
        flex-direction: column;
        margin-top: 46px;
  
        .subtitle {
          margin-bottom: 5px;
  
          font-weight: 400;
          color: #393e46;
        }
  
        input {
          padding: 10px 10px;
          // margin-bottom: 25px;
          margin-bottom: 22px;
          // width: 360px;
          width: 100%;
          // width: 315px;
  
          font-family: $font-roboto;
          font-weight: 200;
          font-size: 16px;
  
          border: 1px solid #DBDBDB;
          border-radius: 3px;
        }
  
        .error-message {
          color: red;
          font-size: 14px;
        }
      }

      .privacy-terms-link {
        margin-top: 40px;

        font-weight: 400;
        font-size: 12px;
        text-decoration: underline;
        text-align: center;
        color: #fff;

        &:hover {
          color: #fff;
        }
      }
    }

    .footer-form-text {
      margin: 35px 0;

      font-size: 16px;
      color: #393E46;
      text-align: center;
      font-weight: 100;

      a {
        font-weight: 500;
        color: #393E46;
      }
    }
  
    .bg-wrapper {
      flex: 0 0 60%;

      background-image: url('../../assets/images/bg-login.jpg');
      background-position: center;
      background-size: cover;
      background-repeat: no-repeat;
    }
  }
}

@include respond-to(xl) {
  .login-container {
    max-width: 1440px;
  }
}

@media screen and (min-width: 1200px) and (max-width: 1440px) {
  .login-container {
    max-width: 1200px;
  }
}