// .DayPickerInput {
//   width: 100%;

//   input {
//     padding: 0.7rem .75rem;
//     width: 100%;

//     font-size: 16px;
//     color: #495057;

//     border: 1px solid #ced4da;
//     border-radius: .25rem;

//     &:focus {
//       color: #495057;

//       outline: 0;
//       background-color: #fff;
//       border-color: #80bdff;
//       box-shadow: none;
//       border-radius: .25rem;
//     }
//   }
// }

input,
select,
.form-control {
  font-size: 16px;

  border-radius: 5px;
}

.rdatez {
  width: 100%;

  input {
    padding: 0.7rem .75rem;
    width: 100%;

    font-size: 16px;
    color: #495057;

    border: 1px solid #ced4da;
    border-radius: .25rem;

    &:focus {
      color: #495057;

      outline: 0;
      background-color: #fff;
      border-color: #80bdff;
      box-shadow: none;
      border-radius: .25rem;
    }
  }
}

.form-group {
  margin-bottom: 1.4rem;

  font-size: 16px;

  &.customer-id-input {
    margin-bottom: 0;
    
    .error-message {
      min-height: 2rem;
      font-size: 15px;
      color: $red;
    }
  }

  &.invalid {
    .error-message {
      font-size: 15px;
      color: $red;
    }

    input {
      border-color: $red;
    }

   
  }
  // margin-bottom: 2rem;
}


.form-control {
  // padding: 1.375rem .75rem;
  // padding: 1rem 1.5rem;
  padding: 0.7rem .75rem;
  height: auto; 

  &:disabled {
    background-color: #f8f9fa;
  }
}

.customer-id-input-autocomplete-assign {
  margin-bottom: 1.4rem;
}

label {
  margin-bottom: 12px;

  color: $black;
}

.select-holder {
  position: relative;

  &:after {
    content: "";

    position: absolute;
    top: 42%;
    right: 15px;

    display: inline-block;
    margin-left: .255em;
    vertical-align: .255em;

    color: $black;
    
    border-top: .3em solid;
    border-right: .3em solid transparent;
    border-bottom: 0;
    border-left: .3em solid transparent;
  }
}

select {
  -webkit-appearance: none;
  -moz-appearance: none;
  text-indent: 1px;
  text-overflow: '';

  &::-ms-expand {
    display: none;
  }
}

.daterangepicker {
  td {
    &.in-range {
      background-color: #F8F9FA;
    }

    &.active {
      background-color: #d0d0d0;
    }
  }

  .drp-buttons {
    .btn {
      padding: 8px;
      // padding: 5px 11px;
      // font-size: 16px;
      // font-family: roboto;

      font-weight: 100;
    }
  }

  .btn {
    font-weight: 100;
    border: none;
    @include on-event {
      box-shadow: none!important;
    }
  }

  .applyBtn {
    background-color: #1104C9;
    transition: .2s ease-in;

    @include on-event {
      // opacity: 0.5;
      background-color: #5950da;
      transition: .2s ease-in;
    }
  }

  .cancelBtn {
    transition: .2s ease-in;

    @include on-event {
      background-color: #f8f9fa;
      transition: .2s ease-in;
    }
  }
}