body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* Ant Design Overrides */
.ant-select-dropdown {
  box-shadow: none !important;
  padding: 0px !important;
  border: 1px solid #ced4da !important;
  border-radius: 5px !important;
  font-weight: 300 !important;
  font-size: 16px !important;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased !important;
}

.ant-select-dropdown-empty .ant-select-item-empty {
  padding: 10px 12px !important;
}

.ant-select-item-option-selected {
  background-color: #f5f5f5 !important;
}

.ant-select-item-option {
  padding: 10px 12px !important;
}

.ant-select-auto-complete {
  border: none !important;
  width: 100% !important;
}

.ant-select-auto-complete > input {
  height: fit-content !important;
}

.ant-select-auto-complete .ant-select-selector {
  display: flex !important;
  height: fit-content !important;
  align-items: center !important;
  box-shadow: none !important;
  padding: 0.4rem 0.75rem !important;
  line-height: 16px !important;
  font-size: 16px !important;
  border-radius: 5px !important;
}

.search-input   .ant-select-auto-complete .ant-select-selector {
  padding: 0.235rem 0.75rem !important;
}

.ant-select-auto-complete .ant-select-selector:focus {
  outline: none !important;
  border-color: #80bdff !important;
}

.ant-select-auto-complete .ant-select-selector:hover {
  border-color: #d9d9d9 !important;
}

.ant-select-auto-complete .ant-select-selector .ant-select-selection-search {
  display: flex !important;
  position: relative !important;
  top: 0px !important;
  left: 0px !important;
  right: 0px !important;
  bottom: 0px !important;
  width: 100% !important;
  line-height: 16px !important;
  max-height: fit-content !important;
  margin: 0px !important;
}

.ant-select-auto-complete .ant-select-selector .ant-select-selection-placeholder {
  position: absolute !important;
  width: 100% !important;
  text-align: left !important;
}