.accordion-table {
  &.plain {

    margin-top: 30px;

    .table-header {
      display: flex;
      justify-content: space-between;
      padding: 20px;

      &.plain {
        padding: 20px 100px 20px 68px;
        
        border-radius: 0;
        border-bottom: 1px solid $light-grey;
      }
    }

    .table-data {
      padding: 15px 0;
      width: 100%;
    }

    .table-row {
      padding-right: 0;
      margin-right: 18px;
      width: auto;
      max-width: 100%;
      justify-content: flex-start;
      
      font-size: 15px;
      font-weight: 100;
      color: #393e46;

      &-title {
        font-size: 15px;
        font-weight: 400;
        color: #393e46;
      }

      &.table-row-hd {
        &.case-type {
          width: 15%;
        }

        &.customer-id {
          width: 16.5%;
        }

        &.status {
          width: 12.5%;
        }

        &.tx-count {
          width: 24%;
        }

        &.address-count {
          margin-right: 40px;
          width: 17%;
        }
      }
    }

    .table-title {
      display: flex;
      align-items: center;

      .icon-menu {
        margin-right: 20px;
      }
    }

    .table-options {
      display: flex;
      align-items: center;

      .icon {
        transition: all .5s ease-in-out;

        &:hover {
          opacity: .7;
          cursor: pointer;
        }
      }

      .icon,
      .opaque {
        margin-right: 12px;
      }

      .btn-icon {
        margin-right: 12px;

        img {
          margin-right: initial;
        }
      }

      .opaque {
        padding: 7px 0;
        width: 150px;
      }

      .opaque,
      .drop-blue {
        max-width: 150px;
      }
    }

    .table-data {
      &.filter-options {
        min-height: 0;
        height: 0;
        padding: 0;

        border: none;
        transition: all 0.15s ease-out;

        &.active {
          min-height: auto;
          height: auto;
          display: flex;
          justify-content: flex-end;
          padding: 20px;
          border-bottom: 1px solid #cbcbcb;

          
          transition: all 0.15s ease-in;


          .form-group,
          .table-select,
          .search-input,
          .search-form {
            min-height: auto;
            height: auto;
            
            transition: all 0.25s ease-in;
          }

          .search-form {
            .btn-search {
              height: 38px;
              
              transition: all 0.25s ease-in;

              img {
                height: auto;
              }
            }
          }

          .table-select {
            padding: 0.7rem .75rem;
          }
        }

        .form-group,
        .table-select,
        .search-input,
        .search-form {
          min-height: 0;
          transition: all 0.15s ease-out;
          height: 0;
          padding: 0;
        }

        .search-form {
          margin-right: 20px;

          .search-input {
            position: relative;
            // padding: 0 10px;
            width: 450px;
            min-height: 40px;

            & .ant-select-selector {
              border: 1px solid #cbcbcb;
              
              &:focus, &:hover {
                border: 1px solid #cbcbcb;
              }

            }
          }

          .btn-search {
            position: absolute;
            top: 1px;
            right: 1px;
            z-index: 5;
            width: 48px;
            height: 0;
            transition: all 0.15s ease-out;
            background-color: #fff;
            border: none;
            border-left: 1px solid #cbcbcb;
            border-top-right-radius: 5px;
            border-bottom-right-radius: 5px;

            img {
              height: 0;
            }
          }
        }
      }
    }

    .table-data {
      &.filter-options {
        .form-group {
          margin-bottom: 0;
        }

        .table-select {
          min-width: 215px;

          background: $white url('../../assets/images/arrow-down-polygon.svg') 0 0 no-repeat;
          background-position-x: 95%;
          background-position-y: 50%;
        }
      }

      .table-header {
        border: 0;
        width: 100%;
      }
    }
  }
}